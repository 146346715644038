import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)
export default new Router({
  routes: [
	{
		path: '/',   //这里配置的是根目录也就是主页 该组件同样需要引入
		component: () => import('@/views/index')
	},
	// {
	// 	path: '/',   //详情页
	// 	component: () => import('@/views/detail'),
	// 	name:'detail'
	// },
	{
		path: '/solution',   //解决方案
		component: () => import('@/views/solution'),
		name:'solution'
	},
	{
		path: '/scene',   //场景应用
		component: () => import('@/views/scene'),
		name:'scene'
	},
	{
		path: '/business',   //业务区域
		component: () => import('@/views/business'),
		name:'business'
	},
	{
		path: '/news',   //新闻资讯
		component: () => import('@/views/news'),
		name:'news'
	},
	{
		path: '/about',   //关于微量数科
		component: () => import('@/views/about'),
		name:'about'
	},
  ]
})
