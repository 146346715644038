<template>
  <div id="app">
    <router-view :key="$route.fullPath"/>
  </div>
</template>

<script>
export default {
  name: 'App',
  beforeCreate() {
    document.querySelector('body').setAttribute('style', 'margin:0;overflow-x: hidden; ')
  },
}
</script>
