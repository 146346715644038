import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from './router'

import echarts from 'echarts';
import 'echarts/map/js/china.js'; //node_modules中

Vue.prototype.$echarts = echarts

import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap,{
  ak : 'XXXXXXXXXX'
})

Vue.use(ElementUI);
Vue.config.productionTip = false


new Vue({
  el: '#app',
  router,
  render: h => h(App)
})